<template>
  <section>
    <top-alternative-section :image="CalendarioImage" :title="$t('gestion-de-citas.topAlternative.title')"
      :content="$t('gestion-de-citas.topAlternative.content')"></top-alternative-section>

    <features-section :title="$t('gestion-de-citas.features.title')" :description="$t('gestion-de-citas.features.description')"
      :items="$t('gestion-de-citas.features.items')"></features-section>

    <contact-section></contact-section>
  </section>
</template>

<script>
import TopAlternativeSection from "../sections/TopAlternativeSection.vue";
import CalendarioImage from "../../assets/calendario.jpg";
import FeaturesSection from "../sections/FeaturesSection.vue";
import ContactSection from "../sections/ContactSection.vue";

export default {
  components: {
    TopAlternativeSection,
    FeaturesSection,
    ContactSection
  },
  data() {
    return {
      CalendarioImage: CalendarioImage,
    };
  }
};
</script>
